.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg-variant);
    padding: 1.2rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-color: var(--color-primary-variant);
    background: transparent;
}

.portfolio__button {
    font-size:0.9rem;
    display: flex;
    align-items: center;
    column-gap: .25rem;
    cursor: pointer;
}

.portfolio__button:hover .portfolio__button-icon {
    transform: translateX(.25rem);
}

.portfolio__button-icon {
    font-size: 1rem;
    transition: .4s;
}

.portfolio__item-image {
    border-radius: 1.5rem;
    overflow: hidden;
}

.portfolio__item h3 {
    margin: 1.2rem 0 2rem;
    color: var(--color-primary);
}

.portfolio__item-cta {
     display: flex;
     gap: 1rem;
     margin-bottom: 1rem;
}

.work__filters {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: .75rem;
    margin-bottom: 2rem;
    color: var(--color-light);
}

.work__item {
    cursor: pointer;
    padding: .25rem .75rem;
    font-weight: 500;
    border-radius: .5rem;
}

.active-work{
    background-color: var(--color-bg-variant);
    color: var(--color-white);
}

.portfolio__modal {
    position: fixed;
    inset: 0;
    background-color: hsla(219, 28%, 16%, .7);
    padding: 2rem 1rem;
    display: grid;
    place-items: center;
    visibility: hidden;
    opacity: 0;
    transition: .4s;
    z-index: 2;
}

.portfolio__modal-content {
    position: relative;
    background-color: var(--color-bg);
    padding: 4.5rem 1.5rem 2.5rem;
    border-radius: 1.5rem;
}

.portfolio__modal-content h3{
    text-align: center;
    margin-bottom: 1rem;
    color: var(--color-primary);
}

.portfolio__modal-content small{
    font-size: 1rem;
    color: var(--color-primary);
}

.portfolio__modal-overview {
    margin-bottom: 1rem;
    font-size: 0.9rem;
}

.portfolio__modal-list {
    display: grid;
    row-gap: .75rem;
}

.portfolio__modal-item {
    display: flex;
    align-items: flex-start;
    column-gap: .5rem;
}

.portfolio__modal-icon {
    color: var(--color-primary);
}

.portfolio__modal-info {
    font-size: .9rem;
}

.portfolio__modal-close {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 1.5rem;
    cursor: pointer;
}

.active-modal{
    opacity: 1;
    visibility: visible;
}

/* ================ MEDIA QUERIES (MEDIUM DEVICES) ================ */

@media screen and (max-width:1024px){
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem
    }

    .portfolio__modal-content small{
        font-size: 0.95rem;
    }

    .portfolio__modal-info {
        font-size: .89rem;
    }

    .portfolio__modal-overview {
        font-size: 0.89rem;
    }
}

/* ================ MEDIA QUERIES (SMALL DEVICES) ================ */

@media screen and (max-width:600px){
    .portfolio__container {
        grid-template-columns: 1fr;
        gap: 1.rem
    }

    .portfolio__modal-content small{
        font-size: 0.9rem;
    }

    .portfolio__modal-info {
        font-size: .85rem;
    }

    .portfolio__modal-overview {
        font-size: 0.85rem;
    }
}
